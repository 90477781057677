import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import "@/scss/style.scss"
import dayjs from 'dayjs'
import ru from 'element-plus/dist/locale/ru.mjs'
import i18n from './plugins/i18n'

dayjs.locale({
    ...ru,
    weekStart: 1
})

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app
    .use(router)
    .use(store)
    .use(ElementPlus, { locale: ru })
    .use(i18n)
    .mount('#app')
