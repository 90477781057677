const newSellTicketObject = () => {
    return {
        eventId: null,
        eventDate: null,
        eventTime: null,
        selectedCityId: null,
        selectedCity: null,
        selectedCategoryId: null,
        selectedCategory: null,
        eventPlace: null,
        eventTitle: null,
        ticketsQuantity: null,
        ticketsItemPrice: null,
        ticketsTotalPrice: null,
        type: "select"
    };
};

export default {
    namespaced: true,
    state() {
        const sellTicketObject = newSellTicketObject();
        return {
            sellTicketObject
        }
    },
    getters: {},
    mutations: {
        resetSellObject(state) {
            state.sellTicketObject = newSellTicketObject();
        },
        setValue(state, { type, item }) {
            console.log(item);
            switch (type) {
                case 'selectedCategory':
                    state.sellTicketObject.selectedCategoryId = item.id;
                    state.sellTicketObject.selectedCategory = item.value;
                    break;
                case 'selectedCity':
                    state.sellTicketObject.selectedCityId = item.id;
                    state.sellTicketObject.selectedCity = item.value;
                    break;
                default:
                    break;
            }
        },
        setSelectedEvent(state, event) {
            console.log(event[0])
            if (event.length > 0) {
                state.sellTicketObject.eventId = event[0].id;
                state.sellTicketObject.eventPlace = event[0].place;
                state.sellTicketObject.eventTitle = event[0].title;
            } else {
                state.sellTicketObject.eventId = null;
                state.sellTicketObject.eventPlace = null;
                state.sellTicketObject.eventTitle = null;
            }
        },
        setSellTicketsType(state, type) {
            state.sellTicketObject.type = type;
        }
    },
    actions: {
        resetSellObject({ commit }) {
            commit("resetSellObject");
        },
        setValue({ commit }, { type, item }) {
            commit("setValue", { type, item });
        },
        setSelectedEvent({ commit }, event) {
            commit("setSelectedEvent", event);
        },
        setSellTicketsType({ commit }, type) {
            commit("setSellTicketsType", type);
        }
    },
};