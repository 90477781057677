import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js'
import ru from '../locales/ru.json'

function loadLocaleMessages() {
  const locales = [{ ru: ru }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key]
  })
  return messages
}

export default createI18n({
  legacy: false,
  locale: 'ru',
  globalInjection: true,
  messages: loadLocaleMessages()
})